<template>
  <div class="update-projects-stage">
    <div class="update-projects-stage-wrapper">
      <div class="block-title-modal border-bottom-solid">
        <span class="title-modal"> {{ $t('update_stade') }} </span>

        <v-btn text class="ml-3 btn-close" @click="handleClose">
          <v-icon class="ml-1">mdi-close</v-icon>
        </v-btn>
      </div>
      <div class="form-update-stage">
        <v-form ref="updateDataStageModal">
          <div class="description-bloc mb-2">
            <v-data-table
              :headers="fields"
              :items="options"
              hide-default-footer
              class="table-substage-immo"
              :fixed-header="true"
              :no-data-text="$t('no_data_table')"
              item-key="id"
            >
              <template v-slot:[`item.checkItem`]="{ item }">
                <v-radio-group
                  v-model="selectedItem"
                  :label="''"
                  class="radio-dynamique"
                  hide-details
                >
                  <v-radio
                    :key="item.id"
                    :label="''"
                    :value="item"
                    class="radio-btn"
                    color="#5C2DD3"
                  ></v-radio
                ></v-radio-group>
              </template>
              <template v-slot:[`item.user`]="{ item }">
                <div class="custom-td">
                  {{
                    item.user
                      ? item.user.first_name + ' ' + item.user.last_name
                      : '-'
                  }}
                </div>
              </template>
              <template v-slot:[`item.date`]="{ item }">
                <div class="custom-td">
                  {{ item.date ? computedDate(item.date) : '-' }}
                </div>
              </template>
            </v-data-table>
          </div>
        </v-form>
      </div>
      <!-- ACTIONS BTN -->
      <div class="action-update-dtage">
        <v-btn
          color="#5C2DD3"
          dark
          @click="handelClickEvent"
          :disabled="selectedItem == null"
          class="btn-modal-immo"
          :class="{ disabled: selectedItem == null }"
        >
          {{ $t('stage_status_list') }}
          <v-icon class="ml-1">mdi-arrow-right</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
export default {
  name: 'update-stage-modal',
  props: {
    options: { required: true }
  },
  data() {
    return {
      selectedItem: null
    }
  },
  computed: {
    ...mapGetters(['getProjectsError']),
    fields() {
      return [
        { text: '', value: 'checkItem', sortable: false },
        { text: this.$t('statut'), value: 'name', sortable: false },
        { text: this.$t('date'), value: 'date', sortable: false },
        { text: this.$t('modified_by'), value: 'user', sortable: false }
      ]
    },
    computedDate() {
      return function(date) {
        if (date) return moment(date).format('MMM,DD,YYYY')
      }
    }
  },
  methods: {
    handleClose() {
      this.$emit('close')
      this.selectedItem = null
      this.$refs.updateDataStageModal.resetValidation()
    },
    async handelClickEvent() {
      this.$emit('selectedItem', this.selectedItem)
    }
  }
}
</script>

<style lang="scss" scoped>
.update-projects-stage {
  position: fixed;
  height: calc(100% - 0px);
  width: 100%;
  top: 0px;
  border: 0;
  background: #d6d6d64a;
  right: 0;
  left: 0;
  z-index: 101;

  .close {
    position: absolute;
    font-size: 20px;
    top: 10px;
    right: 24px;
    cursor: pointer;
    z-index: 1;
  }
  .btn-modal-immo {
    font-size: 12px;
    height: 35px;
    font-size: 12px;
    text-transform: capitalize;
    .v-icon {
      font-size: 16px;
    }
    &.disabled {
      background-color: #bfbfbf !important;
      color: white !important;
    }
  }
  .btn-close {
    &:hover,
    &:focus {
      background: transparent !important;
      color: transparent !important;
    }
    .v-icon {
      color: rgba(0, 0, 0, 0.54);
    }
  }
  .update-projects-stage-wrapper {
    position: fixed;
    width: 500px;
    right: 0px;
    height: 100%;
    background-color: #fff;
    -webkit-box-shadow: 0 6px 16px rgba(0, 0, 0, 0.32);
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.32);
    font-size: 13px;
    color: #5e5e5e;
    .block-title-modal {
      padding: 10px;
      display: flex;
      justify-content: space-between;

      .title-modal {
        font-size: 15px;
        font-weight: 600;
        line-height: 23px;
        letter-spacing: 0em;
        text-align: center;
        color: #212121;
        text-transform: capitalize;
      }
    }
    .form-update-stage {
      padding: 20px 0px;
      position: relative;
      width: 100%;
      max-height: calc(100vh - 127px);
    }
    .action-update-dtage {
      float: right;
    }
    .action-update-dtage,
    .actions-msg-btn-modal {
      padding: 20px 20px 10px 0px;
    }
    .description-bloc {
      position: relative;
      font-weight: 600;
      padding: 4px 0px;
    }
    .server-error {
      margin-top: 20px;
      color: #ff6961;
    }
  }
}
</style>
<style lang="scss">
.table-substage-immo {
  thead th {
    background: #e8e8e8 !important;

    font-size: 12px !important;
    font-weight: 600 !important;
    line-height: 14px !important;
    letter-spacing: 0em;
    text-align: left !important;
    color: #212121 !important;
    border: none !important;
    height: 42px !important;
    text-transform: uppercase;
    padding: 3px 3px 3px 11px !important;
    box-shadow: none !important;
  }
  thead th:not(:first-child) {
    border-right: 1px solid white !important;
  }

  tbody td {
    font-size: 12px !important;
    font-weight: 600 !important;
    line-height: 14px !important;
    letter-spacing: 0em !important;
    text-align: center !important;
    color: #212121 !important;
    border: none !important;
    height: 38px !important;
    padding: 3px 3px 3px 11px !important;
    text-wrap: nowrap;
    // max-width: 100px !important;
  }
  .custom-td {
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: center;
    color: #505050;
    min-width: 90px;
    text-align: start;
  }
  .v-data-table__wrapper {
    max-height: calc(100vh - 182px);
    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: #bdbdbd;
      cursor: pointer !important;
    }
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: 0px 1px 0px 0px #0000001f inset;
      box-shadow: 0px 1px 0px 0px #0000001f inset;
      border-radius: 4px;
      background-color: #eeeeee;
    }
    &::-webkit-scrollbar {
      width: 6px;
      height: 5px;
    }
  }
}
</style>
